import "./Footer.css";
import "../root.css";

function Footer() {
  return (
    <div className="backgroundFooter">
      <div className="footerWhole">
        <div>
          <h3>Free Game Tracker</h3>
        </div>

        <div>
          <p>Data acquired from opera gx</p>
          <p>Copyright © 2022 Kenny and Emily. All Rights Reserved. </p>{" "}
        </div>
      </div>
    </div>
  );
}

export default Footer;
